import { isEmpty } from 'lodash';
import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { BsPencil } from 'react-icons/bs';
import { FiPlus } from 'react-icons/fi';
import React, { useState, useEffect } from 'react';

import './style.css';

import { removeEmptyOptions } from 'helpers/array';
import { extractInitialFormValues } from './utils';
import { handleError } from 'helpers/errorHandler';
import { getFormattedDate } from 'helpers/dateFormats';
import { deleteImportRule, updateImportRule } from 'API/ImportRules';

import DenialsAPI from 'API/DenialsAPI';

import noMoney from 'img/no-money.svg';

import SopPopup from '../AddSop/SopPopup';
import ImportForm from '../ImportForm/ImportForm';
import { AlertDialog } from 'components/common/popup';
import RuleStatusSelect from '../RuleStatusSelect/RuleStatusSelect';
import Button, { BUTTON_TYPE } from 'components/common/button/Button';
import ActionButton from 'components/common/actionButton/ActionButton';

import { ALL_AGENTS_OPTION_VALUE, USER_ROLES } from 'constants/appConstants';
import { addLabelFromValue } from '../AddSop/SopContainer';

export const renderArray = (array = [], config = {}) => {
  const { isPlainArray = true } = config;

  if (array.length === 0) {
    return 'All';
  }
  const plainArray = isPlainArray
    ? [...array]
    : array.map(({ label }) => label);

  const firstArrayElement = plainArray[0];
  const arrayLength = plainArray.length;
  const dataTip = arrayLength.length === 1 ? undefined : plainArray.join(', ');

  const arrayElementsToRender =
    plainArray.length === 1
      ? firstArrayElement
      : `${firstArrayElement} +${arrayLength - 1}`;

  const randomId = Math.random().toString(36).slice(2, 9);
  return (
    <React.Fragment>
      <span
        className="text-decoration-underline"
        data-tip={dataTip}
        data-delay-show="600"
        data-for={randomId}
      >
        {arrayElementsToRender}
      </span>
      <ReactTooltip effect="solid" place="top" id={randomId} />
    </React.Fragment>
  );
};

const ImportRule = (props) => {
  const {
    sNo,
    onCancel,
    userRole,
    importRule,
    onFormSubmit,
    canUpdateRule,
    existingRules,
    dropdownOptions,
    clientPartitionId,
    onRulesDelete,
    isImportRuleFormOpen,
    userInfo,
    onEditClick,
    handleSopChange,
  } = props;
  const [showSopPopup, setShowSopPopup] = useState(false);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);
  const [rulesDeletePopup, setRulesDeletePopup] = useState({
    isOpen: false,
    message: '',
    formValues: {},
  });

  const payers = dropdownOptions.payers?.data || [];
  const reasonCodes = dropdownOptions.reasonCodes?.data || [];
  const cptCodes = dropdownOptions.cptCodes?.data || [];
  const remarkCodes = dropdownOptions.remarkCodes?.data || [];
  const procedureModifiers = dropdownOptions.procedureModifiers?.data || [];

  useEffect(() => {
    if (!importRule.id) {
      setIsFormVisible(true);
    }
  }, [importRule]);

  const handleFormSubmit = (data) => {
    setIsFormVisible(false);
    onFormSubmit(data, true);
  };

  const handleCancelClick = () => {
    setIsFormVisible(false);
    onCancel();
  };

  const handleEditClick = () => {
    setIsFormVisible(true);
    onEditClick();
  };

  const updateRuleStatus = async (newRuleStatus) => {
    if (!importRule.id) return;

    const { value: status } = newRuleStatus;

    setIsUpdatingStatus(true);
    try {
      const [data, countInfo] = await Promise.all([
        updateImportRule(importRule.id, {
          status,
        }),
        DenialsAPI.fetchDenials({
          search: [
            {
              key: 'applied_rule',
              value: importRule.name,
            },
          ],
          onlyTotal: true,
        }),
      ]);

      onFormSubmit(
        {
          ...data,
          denialsCount: countInfo.total,
        },
        true
      );
      setIsUpdatingStatus(false);
    } catch (error) {
      handleError(error);
      setIsUpdatingStatus(false);
    }
  };

  const renderAgents = (array) => {
    if (!array || array.length === 0) {
      return 'Unassigned';
    }

    if (array.includes(ALL_AGENTS_OPTION_VALUE)) {
      return 'All';
    }
    const agentUsernameMap = array
      .map((name) => {
        return dropdownOptions.agents?.data?.find(
          ({ username }) => username === name
        );
      })
      .filter((x) => x)
      .map(({ name }) => name);

    return renderArray(agentUsernameMap.sort());
  };

  const renderDate = (date) => {
    if (!date) return '-';
    return getFormattedDate(date, 'MM/DD/YYYY');
  };

  const extractSelectedArrayOptions = (options = [], selectedIds = []) =>
    options.filter(({ value }) => selectedIds.includes(value));

  const selectedPayers = extractSelectedArrayOptions(
    dropdownOptions.payers?.data,
    importRule.payerIds || []
  );
  const selectedPractices = extractSelectedArrayOptions(
    dropdownOptions.practices?.data,
    importRule.practiceIds || []
  );
  const selectedPracticeGroups = extractSelectedArrayOptions(
    dropdownOptions.practiceGroups?.data,
    importRule?.forGroup?.pgroup || []
  );
  const selectedReasonCodes = extractSelectedArrayOptions(
    dropdownOptions.reasonCodes?.data,
    importRule.reasonCodes || []
  );
  const selectedRemarkCodes = extractSelectedArrayOptions(
    dropdownOptions.remarkCodes?.data,
    importRule.remarkCodes || []
  );
  const selectedProcedureCodes = extractSelectedArrayOptions(
    dropdownOptions.cptCodes?.data,
    importRule.procedureCodes || []
  );
  const selectedProcedureModifiers = extractSelectedArrayOptions(
    dropdownOptions.procedureModifiers?.data,
    importRule.procedureCodeModifiers || []
  );

  const excludedPayers = extractSelectedArrayOptions(
    dropdownOptions.payers?.data,
    importRule.exclude?.payerIds || []
  );

  const excludedReasonCodes = extractSelectedArrayOptions(
    dropdownOptions.reasonCodes?.data,
    importRule.exclude?.reasonCodes || []
  );

  const excludedRemarkCodes = extractSelectedArrayOptions(
    dropdownOptions.remarkCodes?.data,
    importRule.exclude?.remarkCodes || []
  );

  const excludedProcedureCodes = extractSelectedArrayOptions(
    dropdownOptions.cptCodes?.data,
    importRule.exclude?.procedureCodes || []
  );

  const excludedProcedureModifiers = extractSelectedArrayOptions(
    dropdownOptions.procedureModifiers?.data,
    importRule.exclude?.procedureCodeModifiers || []
  );

  const handleProceed = async () => {
    if (!importRule.id) return '';
    setRulesDeletePopup({
      isOpen: false,
      message: '',
      formValues: {},
    });
    try {
      await deleteImportRule(importRule.id);
      onRulesDelete(importRule.id);
    } catch (error) {
      handleError(error);
    }
  };

  const displayExcludePracticesLabel = () => {
    if (importRule?.exclude) {
      const { practiceIds, pgroup } = importRule.exclude;
      const practiceGroupLabel = pgroup?.length
        ? `${pgroup.length} <span title="Practice Group">Practice Group(s)</span> <br/>`
        : '';
      const practiceLabel = practiceIds?.length
        ? `${practiceIds.length} Practice(s)`
        : '';

      return (
        <div
          dangerouslySetInnerHTML={{
            __html: practiceGroupLabel + practiceLabel,
          }}
        />
      );
    }

    return '';
  };

  const sopExists = importRule?.sopUpdatedAt && importRule.sopIds?.length > 0;

  // filter rules dropdown for sop
  const selectedDropdownOptions = {
    payers: {
      data: addLabelFromValue(
        selectedPayers.length > 0
          ? selectedPayers
          : dropdownOptions.payers?.data
      ),
    },
    reasonCodes: {
      data: addLabelFromValue(
        selectedReasonCodes.length > 0
          ? selectedReasonCodes
          : dropdownOptions.reasonCodes?.data
      ),
    },
    remarkCodes: {
      data: addLabelFromValue(
        selectedRemarkCodes.length > 0
          ? selectedRemarkCodes
          : dropdownOptions.remarkCodes?.data
      ),
    },
    cptCodes: {
      data: addLabelFromValue(
        selectedProcedureCodes.length > 0
          ? selectedProcedureCodes
          : dropdownOptions.cptCodes?.data
      ),
    },
    procedureModifiers: {
      data:
        selectedProcedureModifiers.length > 0
          ? selectedProcedureModifiers
          : dropdownOptions.procedureModifiers?.data,
    },
  };

  return (
    <div
      className="import-rule-container card-view__table-row--no-shadow "
      datacy="import-rule"
    >
      <div className="table-body card-view__table-row card-view__table-row--no-shadow ">
        <div className="card-view__table-row-item">
          <div className="d-flex align-item-center">
            <span className="mr-4">{sNo}. </span>
            <div className="width-full" onClick={(e) => e.stopPropagation()}>
              {' '}
              <RuleStatusSelect
                onChange={updateRuleStatus}
                status={importRule.status}
                isLoading={isUpdatingStatus}
                isDisabled={
                  !importRule.id || !canUpdateRule || isUpdatingStatus
                }
                datacy="import-rule-RuleStatusSelect"
              />{' '}
            </div>
          </div>
        </div>
        <div className="card-view__table-row-item business-rule-name">
          {(importRule?.paymentAmount === 0 ||
            importRule?.checkServiceLineZeroPay) && (
            <span data-tip data-for={`zero-pay-icon-${importRule.id}`}>
              <img
                className="rule-name-icon"
                src={noMoney}
                alt="No Money Icon"
              />
              <ReactTooltip
                id={`zero-pay-icon-${importRule.id}`}
                place="right"
                effect="solid"
              >
                <div>Zero Pay Applied</div>
                <div>
                  Check this option to import claim(s) with '$0' payment amount.
                </div>
              </ReactTooltip>
            </span>
          )}

          <span {...(importRule.name ? { 'data-tip': importRule.name } : {})}>
            {importRule.name}
            <ReactTooltip />
          </span>
        </div>
        <div className="card-view__table-row-item">
          <span
            className={classnames('sop-icon-button', {
              'sop-icon-button--disabled':
                !importRule?.id || userRole !== USER_ROLES.CLIENT_ADMIN,
            })}
            onClick={() => {
              importRule?.id &&
                userRole === USER_ROLES.CLIENT_ADMIN &&
                setShowSopPopup(true);
            }}
            data-tip={sopExists ? 'View SOP' : 'Add SOP'}
            data-for={`sop-icon-${importRule.id}`}
          >
            {sopExists ? importRule.sopIds?.length : <FiPlus size="15" />}
          </span>
          <ReactTooltip id={`sop-icon-${importRule.id}`} effect="solid" />
        </div>
        <div className="card-view__table-row-item">
          {renderArray([...selectedPracticeGroups, ...selectedPractices], {
            isPlainArray: false,
          })}

          {importRule?.exclude &&
          (importRule?.exclude?.practiceIds.length > 0 ||
            importRule?.exclude?.pgroup.length) ? (
            <div className="rule-exclusion-display">
              *excluding <br /> {displayExcludePracticesLabel()}{' '}
            </div>
          ) : null}
        </div>
        <div className="card-view__table-row-item">
          {renderArray(importRule.procedureCodes)}
          {importRule?.exclude &&
            importRule?.exclude?.procedureCodes.length > 0 && (
              <div className="rule-exclusion-display">
                *excluding {importRule?.exclude?.procedureCodes.length}{' '}
              </div>
            )}
        </div>
        <div className="card-view__table-row-item">
          {renderArray(importRule.procedureCodeModifiers)}
          {importRule?.exclude &&
            importRule?.exclude?.procedureCodeModifiers.length > 0 && (
              <div className="rule-exclusion-display">
                *excluding {importRule?.exclude?.procedureCodeModifiers.length}{' '}
              </div>
            )}
        </div>
        <div className="card-view__table-row-item">
          {renderArray(importRule.reasonCodes)}
          {importRule?.exclude &&
            importRule?.exclude?.reasonCodes.length > 0 && (
              <div className="rule-exclusion-display">
                *excluding {importRule?.exclude?.reasonCodes.length}{' '}
              </div>
            )}
        </div>
        <div className="card-view__table-row-item">
          {renderArray(importRule.remarkCodes)}
          {importRule?.exclude &&
            importRule?.exclude?.remarkCodes.length > 0 && (
              <div className="rule-exclusion-display">
                *excluding {importRule?.exclude?.remarkCodes.length}{' '}
              </div>
            )}
        </div>
        <div className="card-view__table-row-item">
          {renderArray(selectedPayers, {
            isPlainArray: false,
          })}
          {importRule?.exclude && importRule?.exclude?.payerIds.length > 0 && (
            <div className="rule-exclusion-display">
              *excluding {importRule?.exclude?.payerIds.length}{' '}
            </div>
          )}
        </div>
        <div className="card-view__table-row-item">
          {renderAgents(importRule.agents)}
        </div>
        <div className="card-view__table-row-item">
          {renderDate(importRule.createdAt)}
        </div>
        <div className="card-view__table-row-item">
          {importRule.effectiveDate
            ? renderDate(importRule.effectiveDate)
            : renderDate(importRule.createdAt)}
        </div>

        <div className="card-view__table-row-item d-flex">
          {userRole === USER_ROLES.CLIENT_ADMIN && (
            <ActionButton
              Icon={BsPencil}
              className="mr-16 ml-auto"
              dataTip="Edit"
              onClick={handleEditClick}
              iconClassName="appeal__action--appeal"
              datacy="rules-edit-ActionButton"
              disabled={isImportRuleFormOpen}
            />
          )}
        </div>
      </div>

      {isFormVisible && (
        <ImportForm
          importRule={importRule}
          form={importRule.id ? `import-form-${importRule.id}` : 'import-form'}
          dropdownOptions={dropdownOptions}
          existingRules={existingRules}
          onFormSubmit={handleFormSubmit}
          onCancel={handleCancelClick}
          isCreateForm={!importRule.id}
          clientPartitionId={clientPartitionId}
          initialValues={extractInitialFormValues(importRule, dropdownOptions)}
          datacy="import-rule-ImportForm"
          onRulesDelete={onRulesDelete}
          userInfo={userInfo}
          handleSopChange={handleSopChange}
        />
      )}
      {rulesDeletePopup.isOpen && (
        <AlertDialog
          onClosePressed={() => {
            setRulesDeletePopup({
              isOpen: false,
              message: '',
              formValues: {},
            });
          }}
          className="import-rule-practice-agent-permission"
          datacy="import-form-AlertDialog"
        >
          {rulesDeletePopup.message}
          <hr className="card-horizontal-line" />
          <div className="import-rule-practice-agent-permission__buttons-wrapper">
            <Button
              type={BUTTON_TYPE.SECONDARY}
              title="Delete"
              onClick={handleProceed}
              className="width-80 justify-content-center"
            />
            <Button
              title="Cancel"
              type={BUTTON_TYPE.LIGHT}
              className="width-80 justify-content-center fw-normal ml-8"
              onClick={() => {
                setRulesDeletePopup({
                  isOpen: false,
                  message: '',
                  formValues: {},
                });
              }}
              datacy="cancel-Button"
            />
          </div>
        </AlertDialog>
      )}
      {showSopPopup && (
        <SopPopup
          ruleName={importRule.name}
          ruleId={importRule.id}
          sopId={importRule.sopId}
          onClosePressed={() => setShowSopPopup(false)}
          handleSopChange={handleSopChange}
          {...props}
          dropdownOptions={selectedDropdownOptions}
          selectedPayers={
            !isEmpty(selectedPayers)
              ? selectedPayers
              : removeEmptyOptions(payers)
          }
          excludedPayers={!isEmpty(excludedPayers) ? excludedPayers : []}
          selectedRemarks={
            !isEmpty(selectedRemarkCodes)
              ? selectedRemarkCodes
              : removeEmptyOptions(remarkCodes)
          }
          excludedRemarks={
            !isEmpty(excludedRemarkCodes) ? excludedRemarkCodes : []
          }
          selectedProcedureCodes={
            !isEmpty(selectedProcedureCodes)
              ? selectedProcedureCodes
              : removeEmptyOptions(cptCodes)
          }
          excludedProcedureCodes={
            !isEmpty(excludedProcedureCodes) ? excludedProcedureCodes : []
          }
          selectedReasonCodes={
            !isEmpty(selectedReasonCodes)
              ? selectedReasonCodes
              : removeEmptyOptions(reasonCodes)
          }
          excludedReasonCodes={
            !isEmpty(excludedReasonCodes) ? excludedReasonCodes : []
          }
          selectedProcedureModifierCodes={
            !isEmpty(selectedProcedureModifiers)
              ? selectedProcedureModifiers
              : removeEmptyOptions(procedureModifiers)
          }
          excludedProcedureModifierCodes={
            !isEmpty(excludedProcedureModifiers)
              ? excludedProcedureModifiers
              : []
          }
        />
      )}
    </div>
  );
};

ImportRule.propTypes = {};

export default ImportRule;
