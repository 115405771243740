import moment from 'moment';
import { isEmpty } from 'lodash';
import classnames from 'classnames';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { bindActionCreators } from 'redux';
import ReactDOMServer from 'react-dom/server';
import { FaCalendarAlt } from 'react-icons/fa';
import React, { useState, useMemo } from 'react';
import {
  Field,
  reduxForm,
  SubmissionError,
  change,
  formValueSelector,
} from 'redux-form/immutable';

import './style.css';

import Input from 'components/common/input';
import Button from 'components/common/button';
import ToggleSwitch from 'components/common/toggle';
import Select from 'components/common/select/Select';
import { AlertDialog } from 'components/common/popup';
import { BUTTON_TYPE } from 'components/common/button/Button';
import CustomDropdown from 'components/common/dropdown/ButtonDropdown/CustomDropdown';
import { renderReduxMaskedInput } from 'components/CreateAppeal/reduxMaskedInput';

import { getUserPracticeIdentifiers } from 'helpers/user';
import { fetchPracticeGroups } from 'API/AccountSettingsAPI';

import { MASK_DATE } from 'helpers/masks';
import inCompleteIcon from 'img/incomplete.svg';

import { handleError } from 'helpers/errorHandler';
import {
  createImportRule,
  fetchRuleActions,
  updateImportRule,
} from 'API/ImportRules';
import {
  APPEALIO_SUPPORT_EMAIL,
  RULE_STATUSES,
  ALL_AGENTS_OPTION_VALUE,
  ALL_VALUE,
  ZERO_PAY_RULE_OPTIONS,
} from 'constants/appConstants';

import { getFormattedDate } from 'helpers/dateFormats';

import { isDuplicateRuleName, mapToRequestData } from './utils';
import { sortByCount } from 'components/common/dropdown/ButtonDropdown/utils';
import {
  DROPDOWN_OPTIONS_STATE_KEYS,
  getDropdownOptions,
} from 'redux/reducers/dropdownOptionsStore';

import PracticeSelect from 'components/ApDashboard/Manager/Settings/Organization/Users/PracticeSelect/PracticeSelectWithCustomDropdown';
import { OPTION_TYPES } from 'components/ApDashboard/Manager/Settings/Organization/Users/PracticeSelect/constant';
import { arrayDifference } from 'helpers/array';

const CLAIM_LINE_RELATED_DROPDOWN_LABEL = {
  reasonCodes: 'Reason(s)',
  remarkCodes: 'Remark(s)',
  procedureCodes: 'Procedure(s)',
  procedureModifierCodes: 'Modifier(s)',
};

export const INITIAL_FORM_VALUES = {
  name: '',
  practices: [],
  excludedPractices: [],
  payers: [],
  excludedPayers: [],
  minimumDeniedAmount: '',
  procedureCodes: [],
  excludedProcedureCodes: [],
  reasonCodes: [],
  excludedReasonCodes: [],
  remarkCodes: [],
  excludedRemarkCodes: [],
  agents: [],
  providers: [],
  effectiveDate: getFormattedDate(new Date(), 'MM/DD/YYYY'),
};

export const TOGGLE_OPTIONS = {
  option1: '',
  option2: 'Exclusions',
};

const removeEmptyOptions = (options) => options.filter(({ value }) => value);

let ImportForm = (props) => {
  const {
    importRule = {},
    dropdownOptions,
    handleSubmit,
    error,
    pristine,
    submitting,
    onFormSubmit,
    isCreateForm = false,
    onCancel,
    clientPartitionId,
    existingRules = [],
    selectedPayers,
    excludedPayers,
    selectedRemarks,
    excludedRemarks,
    selectedPractices,
    excludedPractices,
    selectedProcedureCodes,
    excludedProcedureCodes,
    selectedReasonCodes,
    excludedReasonCodes,
    selectedProcedureModifierCodes,
    excludedProcedureModifierCodes,
    isZeroPayAnyClaimLineDisabled,
    isSelectedZeoClaimLineOptionWithoutAnyClaimLine,
  } = props;

  const [isExclusionToggleSelected, setIsExclusionToggleSelected] =
    useState(false);

  const [claimLinePopup, setClaimLinePopup] = useState({
    isOpen: false,
    dropdownName: false,
    options: [],
  });

  const payers = dropdownOptions.payers?.data || [];
  const reasonCodes = dropdownOptions.reasonCodes?.data || [];
  const cptCodes = dropdownOptions.cptCodes?.data || [];
  const practices = removeEmptyOptions(dropdownOptions.practices?.data || []);
  const practiceGroups = removeEmptyOptions(
    dropdownOptions.practiceGroups?.data.filter(
      (x) => x.label !== 'Ungrouped'
    ) || []
  );
  const remarkCodes = dropdownOptions.remarkCodes?.data || [];
  const procedureModifiers = dropdownOptions.procedureModifiers?.data || [];
  const agents = dropdownOptions.agents?.data || [];

  const [duplicateRuleNamePopup, setDuplicateRuleNamePopup] = useState({
    isOpen: false,
  });

  const [agentPracticePermissionPopup, setAgentPracticePermissionPopup] =
    useState({
      isOpen: false,
      message: '',
      formValues: {},
    });

  const closeDuplicateRuleNamePopup = () =>
    setDuplicateRuleNamePopup({
      isOpen: false,
    });

  const addOrUpdateRule = async (values) => {
    try {
      const ruleActions = await fetchRuleActions();
      if (ruleActions.length === 0) {
        throw Error(
          `Rule cannot be created. Please contact ${APPEALIO_SUPPORT_EMAIL} for support.`
        );
      }

      /**
       * INFO: For now one action is available, and it is to be used
       * while creating a rule using the API.
       **/
      const [{ name: actionName }] = ruleActions;
      const mappedData = mapToRequestData({
        ...values,
        clientPartitionId,
        action: actionName,
        status: importRule.status || RULE_STATUSES.ACTIVE,
      });

      const data = isCreateForm
        ? await createImportRule(mappedData)
        : await updateImportRule(importRule.id, mappedData);

      onFormSubmit(data);
    } catch (error) {
      handleError(error);
    }
  };

  const onSubmit = async (formValue) => {
    const values = formValue.toJS();
    if (
      [
        values.reasonCodes,
        values.remarkCodes,
        values.procedureCodes,
        values.procedureModifierCodes,
        values.excludedReasonCodes,
        values.excludedRemarkCodes,
        values.excludedProcedureCodes,
        values.excludedProcedureModifierCodes,
      ].every(isEmpty)
    ) {
      throw new SubmissionError({
        _error:
          '* Please select/exclude at least one Remark, or Procedure, or Modifier, or Reason code.',
      });
    }

    const existingRulesWithoutCurrentRule = existingRules.filter(
      (rule) => rule.id && rule.id !== importRule.id
    );

    if (isDuplicateRuleName(existingRulesWithoutCurrentRule, values.name)) {
      return setDuplicateRuleNamePopup({
        isOpen: true,
      });
    }

    const practiceAgentPermissionMessage =
      await getPracticeAgentPermissionMessage(values);

    if (practiceAgentPermissionMessage) {
      return setAgentPracticePermissionPopup({
        isOpen: true,
        message: practiceAgentPermissionMessage,
        formValues: values,
      });
    }

    addOrUpdateRule(values);
  };

  /**
   * Calculates and returns a permission message based on the practices and agents provided.
   *
   * @param {Object} values - The input values containing practices and agents.
   * @param {Array} values.practices - List of practices.
   * @param {Array} values.agents - List of agents.
   * @returns {Promise<string>} - A message describing agent-practice permissions.
   */
  async function getPracticeAgentPermissionMessage(values) {
    // If no agents are selected, return a default message
    if (!values.agents || !values?.agents?.length) {
      return `No agent(s) have been assigned to this rule. All matching claims for these selected practice(s) will default to "Unassigned."`;
    }

    const selectedPracticeIdentifiers = values.practices
      .filter((p) => p.type === OPTION_TYPES.PRACTICE)
      .map((p) => p.value);

    const selectedPracticeGroupIds = values.practices
      .filter((p) => p.type === OPTION_TYPES.PRACTICE_GROUP)
      .map((pg) => pg.value);

    const selectedAgents = values.agents.some(
      (agent) => agent.value === ALL_AGENTS_OPTION_VALUE
    )
      ? props.allAgents
      : values.agents;

    const allPracticeGroupIdsToFetch = [
      ...selectedPracticeGroupIds,
      ...selectedAgents.flatMap((agent) => agent?.allowedItems?.pgroup || []),
    ];

    const allPracticeGroups = allPracticeGroupIdsToFetch.length
      ? await fetchPracticeGroups({ all: 1, ids: allPracticeGroupIdsToFetch })
      : [];

    const selectedPracticeGroupsData = allPracticeGroups.filter((pg) =>
      selectedPracticeGroupIds.includes(pg.id)
    );

    const practicesFromSelectedGroups = selectedPracticeGroupsData.flatMap(
      (pg) => pg.practices
    );
    const allSelectedPractices = [
      ...new Set([
        ...selectedPracticeIdentifiers,
        ...practicesFromSelectedGroups,
      ]),
    ];

    // Compute practices associated with each agent and identify unassigned practices
    const agentPractices = selectedAgents
      .map((agent) => {
        const agentAllowedPractices = getUserPracticeIdentifiers(
          agent,
          selectedPracticeGroupsData,
          selectedPracticeIdentifiers
        );
        const unassignedPractices = arrayDifference(
          allSelectedPractices,
          agentAllowedPractices
        );

        return {
          agent: agent.name,
          unassignedPractices,
          totalUnassignedPractice: unassignedPractices.length,
        };
      })
      .filter((ap) => ap.totalUnassignedPractice);

    if (!agentPractices.length && values.agents.length) return '';

    // Determine practices that are unassigned across all agents
    const practicesWithoutAgents = agentPractices
      .map((ap) => ap.unassignedPractices)
      .reduce((pre, cur) => pre.filter((item) => cur.includes(item)));

    return practicesWithoutAgents.length === allSelectedPractices.length &&
      agentPractices.length === selectedAgents.length
      ? `The following selected practice(s) are lacking a corresponding selected agent(s), all matching claims for these selected practice(s) will default to "Unassigned."`
      : `One or more of the selected agent(s) do not have access to one or more of the selected practice(s). To update a users' access, please navigate to the Account Settings page.`;
  }

  const handleProceed = async () => {
    const formValues = agentPracticePermissionPopup.formValues;
    setAgentPracticePermissionPopup({
      isOpen: false,
      message: '',
      formValues: {},
    });
    await addOrUpdateRule(formValues);
  };

  const isAllAgentsSelected = (selectedAgents) => {
    return selectedAgents.some(
      (option) => option.value === ALL_AGENTS_OPTION_VALUE
    );
  };

  const handleAgentChange = (selectedOptions) => {
    const isAllAgentsSelectedPrev = isAllAgentsSelected(props.selectedAgents);
    const isAllAgentsSelectedCur = isAllAgentsSelected(selectedOptions);

    if (!isAllAgentsSelectedPrev && isAllAgentsSelectedCur) {
      return props.change('agents', [
        {
          label: 'All',
          value: ALL_AGENTS_OPTION_VALUE,
        },
      ]);
    } else if (isAllAgentsSelectedCur && selectedOptions.length > 1) {
      const optionsWithoutAllOption = selectedOptions.filter(
        (option) => option.value !== ALL_AGENTS_OPTION_VALUE
      );
      return props.change('agents', optionsWithoutAllOption);
    }
  };

  const handleToggle = () =>
    setIsExclusionToggleSelected(!isExclusionToggleSelected);

  const isAllPracticeIncluded =
    selectedPractices.length === 0 ||
    selectedPractices.size === 0 ||
    selectedPractices.some((x) => x?.value === ALL_VALUE);

  const shouldDisableDropdownOptions = (selectedItems, excludedItems) => {
    const isSelectedItemsEmpty =
      selectedItems.length === 0 || selectedItems.size === 0;
    const isExcludedItemsEmpty =
      excludedItems.length === 0 || excludedItems.size === 0;
    const disableSelectedItems = isSelectedItemsEmpty && !isExcludedItemsEmpty;
    const disableExcludedItems = isExcludedItemsEmpty && !isSelectedItemsEmpty;

    return {
      disableSelectedItems,
      disableExcludedItems,
    };
  };

  const {
    disableSelectedItems: disablePayers,
    disableExcludedItems: disabledExcludedPayers,
  } = shouldDisableDropdownOptions(selectedPayers, excludedPayers);

  const {
    disableSelectedItems: disableRemarks,
    disableExcludedItems: disabledExcludedRemarks,
  } = shouldDisableDropdownOptions(selectedRemarks, excludedRemarks);

  const {
    disableSelectedItems: disableProceduresCodes,
    disableExcludedItems: disabledExcludedProceduresCodes,
  } = shouldDisableDropdownOptions(
    selectedProcedureCodes,
    excludedProcedureCodes
  );

  const {
    disableSelectedItems: disableModifierCodes,
    disableExcludedItems: disabledExcludedModifierCodes,
  } = shouldDisableDropdownOptions(
    selectedProcedureModifierCodes,
    excludedProcedureModifierCodes
  );

  const {
    disableSelectedItems: disableReasonCodes,
    disableExcludedItems: disabledExcludedReasonCodes,
  } = shouldDisableDropdownOptions(selectedReasonCodes, excludedReasonCodes);

  const isSelectedPracticesEmpty =
    selectedPractices.length === 0 || selectedPractices.size === 0;
  const isExcludedPracticesEmpty =
    excludedPractices.length === 0 || excludedPractices.size === 0;
  const disablePracticeSelect =
    (isSelectedPracticesEmpty && !isExcludedPracticesEmpty) ||
    (excludedPractices.filter(
      (practice) => practice.type === OPTION_TYPES.PRACTICE
    ).length >= 1 &&
      excludedPractices.every(
        (practice) => practice.type === OPTION_TYPES.PRACTICE
      ) &&
      selectedPractices.filter(
        (practice) => practice.type === OPTION_TYPES.PRACTICE_GROUP
      ).length < 1);

  const disableExcludedPracticeSelect =
    selectedPractices.filter(
      (practice) => practice.type === OPTION_TYPES.PRACTICE
    ).length >= 1 &&
    selectedPractices.filter(
      (practice) => practice.type === OPTION_TYPES.PRACTICE_GROUP
    ).length < 1;

  let practicesOptions = practices;
  if (
    excludedPractices.filter((x) => x.type === OPTION_TYPES.PRACTICE).length
  ) {
    practicesOptions = practices.filter(
      (practice) =>
        !excludedPractices.some(
          (excludedPractice) =>
            excludedPractice.type === OPTION_TYPES.PRACTICE &&
            excludedPractice.value === practice.value
        )
    );
  }
  if (
    excludedPractices.filter((x) => x.type === OPTION_TYPES.PRACTICE_GROUP)
      .length
  ) {
    practicesOptions = practicesOptions.filter(
      (practice) =>
        !excludedPractices.some(
          (excludedPractice) =>
            excludedPractice.type === OPTION_TYPES.PRACTICE_GROUP &&
            practice.inGroups.includes(excludedPractice.label)
        )
    );
  }

  let practiceGroupOptions = practiceGroups;

  if (
    excludedPractices.filter((x) => x.type === OPTION_TYPES.PRACTICE_GROUP)
      .length
  ) {
    practiceGroupOptions = practiceGroupOptions.filter(
      (practiceGroup) =>
        !excludedPractices.some(
          (excludedPractice) =>
            excludedPractice.type === OPTION_TYPES.PRACTICE_GROUP &&
            excludedPractice.value === practiceGroup.value
        )
    );
  }

  const selectedPracticesOptions = selectedPractices.size
    ? selectedPractices.toJS()
    : selectedPractices;

  // If 'ALL_VALUE' isn't selected, filter out practices that belong to selected practice groups.
  const excludePracticeOptions = isAllPracticeIncluded
    ? practices
    : practices.filter((practice) =>
        selectedPracticesOptions
          .filter(
            (selectedPractice) =>
              selectedPractice.type === OPTION_TYPES.PRACTICE_GROUP
          )
          .some((pg) => practice.inGroups.includes(pg.label))
      );

  // If 'ALL_VALUE' isn't selected, exclude practice groups that are already selected.
  const excludedPracticeGroupOptions = isAllPracticeIncluded
    ? practiceGroups
    : practiceGroups.filter(
        () =>
          !selectedPracticesOptions.some(
            (selectedPractice) =>
              selectedPractice.type === OPTION_TYPES.PRACTICE_GROUP
          )
      );

  const zeroPayClaimLineOptions = useMemo(() => {
    return ZERO_PAY_RULE_OPTIONS.map((option) =>
      option.value === 'ANY_CLAIM_LINE'
        ? {
            ...option,
            isDisabled: isZeroPayAnyClaimLineDisabled,
            tooltip: ReactDOMServer.renderToStaticMarkup(
              <div>
                The "Zero Pay - Any Claim Line" option is available when the
                Procedure Code, Modifier, Remark Code, <br />
                and Reason Code fields contain a single selection or "All".
              </div>
            ),
            isToolTipDataHtml: true,
          }
        : option
    );
  }, [isZeroPayAnyClaimLineDisabled]);

  const checkAndUpdateZeroPayOptions = (selectedValues, key) => {
    if (
      selectedValues?.length > 1 &&
      !isSelectedZeoClaimLineOptionWithoutAnyClaimLine
    ) {
      setClaimLinePopup({
        isOpen: true,
        options: selectedValues,
        dropdownName: key,
      });
    }
  };

  const handleCancelClaimLinePopup = () => {
    const { dropdownName, options } = claimLinePopup;
    props.change(dropdownName, options.slice(0, -1));
    setClaimLinePopup({
      isOpen: false,
      options: [],
      dropdownName: '',
    });
  };

  const handleContinueClaimLinePopup = () => {
    setClaimLinePopup({
      isOpen: false,
      dropdownName: '',
      options: [],
    });
    props.change('onlyZeroPayRule', ZERO_PAY_RULE_OPTIONS[0]);
  };

  return (
    <form
      className="rules-form"
      onSubmit={handleSubmit(onSubmit)}
      datacy="import-form"
    >
      <div className="row">
        <div className="col-lg-2 pb-10 right-border-grey">
          <Field
            component={Input}
            name="name"
            label="Rule Name *"
            placeholder="Name"
            datacy="rule-name-Field"
            readOnly={Boolean(importRule.id)}
          />
        </div>

        <div className="col-lg-2 pb-10">
          <Field
            component={CustomDropdown}
            name="payers"
            options={removeEmptyOptions(payers)}
            label="Payer(s)"
            className={classnames('d-block', {
              'd-none': isExclusionToggleSelected,
            })}
            placeholder="All"
            isMulti
            datacy="payers-Field"
            {...(disablePayers && {
              dataTipMessage:
                "Since you've chosen to exclude payers, including them is not an option.",
            })}
            isDisabled={disablePayers}
          />
          <Field
            component={CustomDropdown}
            name="excludedPayers"
            className={classnames('dd-exclusion-default', {
              'd-block': isExclusionToggleSelected,
            })}
            options={removeEmptyOptions(payers)}
            label="Excluded Payer(s)"
            placeholder="None Selected"
            isMulti
            datacy="payers-Field"
            isDisabled={disabledExcludedPayers}
            {...(disabledExcludedPayers && {
              dataTipMessage:
                "Since you've chosen to include payers, excluding them is not an option.",
            })}
          />
        </div>

        <div className="col-lg-2 pb-10">
          <Field
            component={CustomDropdown}
            options={removeEmptyOptions(remarkCodes)}
            name="remarkCodes"
            onChange={(v) => checkAndUpdateZeroPayOptions(v, 'remarkCodes')}
            className={classnames('d-block', {
              'd-none': isExclusionToggleSelected,
            })}
            isMulti
            label="Remark(s)"
            placeholder="All"
            datacy="remark-codes-Field"
            {...(disableRemarks && {
              dataTipMessage:
                "Since you've chosen to exclude Remarks, including them is not an option.",
            })}
            isDisabled={disableRemarks}
            sortFunc={sortByCount}
          />
          <Field
            component={CustomDropdown}
            name="excludedRemarkCodes"
            className={classnames('dd-exclusion-default', {
              'd-block': isExclusionToggleSelected,
            })}
            options={removeEmptyOptions(remarkCodes)}
            label="Excluded Remark(s)"
            placeholder="None Selected"
            isMulti
            datacy="excluded-remark-codes-Field"
            {...(disabledExcludedRemarks && {
              dataTipMessage:
                "Since you've chosen to include Remarks, excluding them is not an option.",
            })}
            isDisabled={disabledExcludedRemarks}
          />
        </div>
        <div className="col-lg-4 pb-10">
          <Field
            label="Practice(s)"
            className={classnames('d-block', {
              'd-none': isExclusionToggleSelected,
            })}
            component={PracticeSelect}
            practiceGroupOptions={removeEmptyOptions(practiceGroupOptions)}
            practiceOptions={practicesOptions}
            name="practices"
            {...(disablePracticeSelect && {
              dataTipMessage:
                "Since you've chosen to exclude Practice, including them is not an option.",
            })}
            isDisabled={disablePracticeSelect}
          />
          <Field
            label="Excluded Practice(s)"
            className={classnames('dd-exclusion-default', {
              'd-block': isExclusionToggleSelected,
            })}
            component={PracticeSelect}
            practiceGroupOptions={removeEmptyOptions(
              excludedPracticeGroupOptions
            )}
            practiceOptions={excludePracticeOptions}
            name="excludedPractices"
            isExclusionDropdown
            placeholder="None Selected"
            {...(disableExcludedPracticeSelect && {
              dataTipMessage:
                "Since you've chosen to include Practice, excluding them is not an option.",
            })}
            isDisabled={disableExcludedPracticeSelect}
          />
        </div>
        <div className="col-lg-2 pb-10">
          <ToggleSwitch
            className={classnames('justify-content-center ', {
              'toggle-wrapper--off': !isExclusionToggleSelected,
            })}
            options={TOGGLE_OPTIONS}
            checked={isExclusionToggleSelected}
            handleToggle={handleToggle}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-lg-2 pb-10 right-border-grey">
          <Field
            label="Effective Date *"
            name="effectiveDate"
            size="10"
            type="string"
            mask={MASK_DATE}
            placeholder="MM/DD/YYYY"
            component={renderReduxMaskedInput}
            showError={true}
            Icon={FaCalendarAlt}
            datacy="effective-date-Field"
            disabled={Boolean(importRule.id)}
          />
        </div>
        <div className="col-lg-2 pb-10">
          <Field
            component={CustomDropdown}
            options={removeEmptyOptions(cptCodes)}
            onChange={(v) => checkAndUpdateZeroPayOptions(v, 'procedureCodes')}
            name="procedureCodes"
            className={classnames('d-block', {
              'd-none': isExclusionToggleSelected,
            })}
            label="Procedure(s)"
            placeholder="All"
            isMulti
            datacy="procedure-codes-Field"
            {...(disableProceduresCodes && {
              dataTipMessage:
                "Since you've chosen to exclude Procedures, including them is not an option.",
            })}
            isDisabled={disableProceduresCodes}
          />
          <Field
            component={CustomDropdown}
            name="excludedProcedureCodes"
            className={classnames('dd-exclusion-default', {
              'd-block': isExclusionToggleSelected,
            })}
            options={removeEmptyOptions(cptCodes)}
            label="Excluded Procedure(s)"
            placeholder="None Selected"
            isMulti
            datacy="excluded-procedure-codes-Field"
            {...(disabledExcludedProceduresCodes && {
              dataTipMessage:
                "Since you've chosen to include Procedures, excluding them is not an option.",
            })}
            isDisabled={disabledExcludedProceduresCodes}
          />
        </div>

        <div className="col-lg-2 pb-10">
          <Field
            component={CustomDropdown}
            options={removeEmptyOptions(procedureModifiers)}
            name="procedureModifierCodes"
            onChange={(v) =>
              checkAndUpdateZeroPayOptions(v, 'procedureModifierCodes')
            }
            label="Modifier(s)"
            className={classnames('d-block', {
              'd-none': isExclusionToggleSelected,
            })}
            isMulti
            placeholder="All"
            datacy="procedure-modifier-codes-Field"
            {...(disableModifierCodes && {
              dataTipMessage:
                "Since you've chosen to exclude Modifiers, including them is not an option.",
            })}
            isDisabled={disableModifierCodes}
          />
          <Field
            component={CustomDropdown}
            name="excludedProcedureModifierCodes"
            className={classnames('dd-exclusion-default', {
              'd-block': isExclusionToggleSelected,
            })}
            options={removeEmptyOptions(procedureModifiers)}
            label="Excluded Modifier(s)"
            placeholder="None Selected"
            isMulti
            datacy="excluded-modifier-codes-Field"
            {...(disabledExcludedModifierCodes && {
              dataTipMessage:
                "Since you've chosen to exclude Modifiers, including them is not an option.",
            })}
            isDisabled={disabledExcludedModifierCodes}
          />
        </div>

        <div className="col-lg-2 pb-10 ">
          <Field
            component={CustomDropdown}
            options={removeEmptyOptions(reasonCodes)}
            onChange={(v) => checkAndUpdateZeroPayOptions(v, 'reasonCodes')}
            name="reasonCodes"
            label="Reason(s)"
            placeholder="All"
            className={classnames('d-block', {
              'd-none': isExclusionToggleSelected,
            })}
            isMulti
            datacy="reason-codes-Field"
            {...(disableReasonCodes && {
              dataTipMessage:
                "Since you've chosen to exclude Reasons, including them is not an option.",
            })}
            isDisabled={disableReasonCodes}
            sortFunc={sortByCount}
          />
          <Field
            component={CustomDropdown}
            name="excludedReasonCodes"
            className={classnames('dd-exclusion-default', {
              'd-block': isExclusionToggleSelected,
            })}
            options={removeEmptyOptions(reasonCodes)}
            label="Excluded Reason(s)"
            placeholder="None Selected"
            isMulti
            datacy="excluded-reason-codes-Field"
            {...(disabledExcludedReasonCodes && {
              dataTipMessage:
                "Since you've chosen to exclude Reasons, including them is not an option.",
            })}
            isDisabled={disabledExcludedReasonCodes}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-2 pb-10 right-border-grey">
          <Field
            component={CustomDropdown}
            options={removeEmptyOptions(agents)}
            name="agents"
            label="Assign to Agent(s)"
            placeholder="Select Agent"
            isMulti
            onChange={handleAgentChange}
            datacy="agents-Field"
            isDisabled={isExclusionToggleSelected}
          />
        </div>
        <div
          className={classnames('col-lg-4 pb-10', {
            'd-none': isExclusionToggleSelected,
          })}
        >
          <div className="d-flex align-items-center">
            <p className="dd-label">Payment Amount</p>
          </div>
          <div className="d-flex">
            <label
              className="fw-normal mt-8 width-120"
              data-tip
              data-for={`zero-pay-form-${importRule.id}`}
            >
              <Field
                name="onlyZeroPay"
                type="checkbox"
                component="input"
                label="Zero Pay"
              />
              <span className="ml-8">Zero Pay</span>
            </label>
            <ReactTooltip
              id={`zero-pay-form-${importRule.id}`}
              place="right"
              effect="solid"
            >
              Check this option to import claim(s) with '$0' payment amount.
            </ReactTooltip>
            <Field
              component={Select}
              name="onlyZeroPayRule"
              isDisabled={!props?.isOnlyZeroPaySelected}
              options={zeroPayClaimLineOptions}
              placeholder="None Selected"
              datacy="excluded-procedure-codes-Field"
            />
          </div>
        </div>
      </div>

      <div className="d-flex justify-content--space-between">
        <div className="fs-14">{error}</div>
        <div className="d-flex justify-content-flex-end">
          <Button
            title={importRule.id ? 'Update' : 'Create'}
            className="width-80 ap-button--secondary justify-content-center mr-8 height-40"
            disabled={submitting || pristine}
            datacy="update-create-Button"
          />
          <Button
            title="Cancel"
            type={BUTTON_TYPE.LIGHT}
            className="width-80 justify-content-center fw-normal height-40"
            disabled={submitting}
            onClick={onCancel}
            datacy="cancel-Button"
          />
        </div>
      </div>

      {duplicateRuleNamePopup.isOpen && (
        <AlertDialog
          onClosePressed={closeDuplicateRuleNamePopup}
          statusIcon={inCompleteIcon}
          datacy="import-form-AlertDialog"
        >
          Rule name must be unique.Use a different name.
        </AlertDialog>
      )}

      {agentPracticePermissionPopup.isOpen && (
        <AlertDialog
          onClosePressed={() => {
            setAgentPracticePermissionPopup({
              isOpen: false,
              message: '',
              formValues: {},
            });
          }}
          className="import-rule-practice-agent-permission"
          datacy="import-form-AlertDialog"
        >
          {agentPracticePermissionPopup.message}
          <hr className="card-horizontal-line" />
          <div className="import-rule-practice-agent-permission__buttons-wrapper">
            <Button
              type="secondary"
              title="Proceed Anyway"
              onClick={handleProceed}
            />
            <Button
              title="Cancel"
              type={BUTTON_TYPE.LIGHT}
              className="width-80 justify-content-center fw-normal ml-8"
              onClick={() => {
                setAgentPracticePermissionPopup({
                  isOpen: false,
                  message: '',
                  formValues: {},
                });
              }}
              datacy="cancel-Button"
            />
          </div>
        </AlertDialog>
      )}
      {claimLinePopup.isOpen && (
        <AlertDialog
          onClosePressed={() => {
            setClaimLinePopup({
              isOpen: false,
              dropdownName: '',
              options: [],
            });
          }}
          className="import-rule-practice-agent-permission"
          datacy="import-form-AlertDialog"
        >
          <h3>Are you sure you would like to continue?</h3>
          <p>
            Selecting a second{' '}
            {CLAIM_LINE_RELATED_DROPDOWN_LABEL[claimLinePopup.dropdownName]} is
            not compatible with “Zero Pay - Any Claim Line selection”.
            Continuing will automatically change the Zero Pay filter to “Zero
            Pay - Across Entire Claim”.
          </p>
          <hr className="card-horizontal-line" />
          <div className="import-rule-practice-agent-permission__buttons-wrapper">
            <Button
              type="secondary"
              title="Continue"
              onClick={handleContinueClaimLinePopup}
            />
            <Button
              title="Cancel"
              type={BUTTON_TYPE.LIGHT}
              className="width-80 justify-content-center fw-normal ml-8"
              onClick={handleCancelClaimLinePopup}
              datacy="cancel-Button"
            />
          </div>
        </AlertDialog>
      )}
    </form>
  );
};

const validate = (formValues) => {
  const values = formValues.toJS();
  const errors = {};

  if (!values.name) {
    errors.name = 'Rule name is required';
  }

  if (values.name && values.name.length >= 40) {
    errors.name = 'Rule name cannot be more than 40 characters.';
  }

  if (!values.effectiveDate) {
    errors.effectiveDate = 'Effective Date is required';
  }
  if (!values.ruleId) {
    const threeMonthsAgo = moment().subtract(3, 'months');
    if (moment(values.effectiveDate).isBefore(threeMonthsAgo)) {
      errors.effectiveDate =
        'Effective Date cannot exceed 3 months prior to today’s date.';
    }
  }

  if (values.effectiveDate) {
    if (!moment(values.effectiveDate).isValid()) {
      errors.effectiveDate = 'Effective Date is invalid';
    }

    if (moment(values.effectiveDate).isAfter(moment())) {
      errors.effectiveDate = 'Effective Date cannot be from the future';
    }
  }

  return errors;
};

ImportForm.propTypes = {};

ImportForm = reduxForm({
  enableReinitialize: true,
  validate,
  initialValues: INITIAL_FORM_VALUES,
})(ImportForm);

const mapStateToProps = (state, props) => {
  const allAgents =
    getDropdownOptions(state, DROPDOWN_OPTIONS_STATE_KEYS.AGENT_OPTIONS)
      ?.data || [];
  const selector = formValueSelector(props.form);

  const mapToJSValues = (key, defaultValue = []) =>
    (selector(state, key)?.toJS?.() ?? selector(state, key)) || defaultValue;

  const selectedAgents = mapToJSValues('agents');
  const selectedPayers = mapToJSValues('payers');
  const excludedPayers = mapToJSValues('excludedPayers');
  const selectedRemarks = mapToJSValues('remarkCodes');
  const excludedRemarks = mapToJSValues('excludedRemarkCodes');
  const selectedProcedureCodes = mapToJSValues('procedureCodes');
  const excludedProcedureCodes = mapToJSValues('excludedProcedureCodes');
  const isOnlyZeroPaySelected = mapToJSValues('onlyZeroPay', false);
  const selectedReasonCodes = mapToJSValues('reasonCodes');
  const excludedReasonCodes = mapToJSValues('excludedReasonCodes');
  const selectedProcedureModifierCodes = mapToJSValues(
    'procedureModifierCodes'
  );
  const excludedProcedureModifierCodes = mapToJSValues(
    'excludedProcedureModifierCodes'
  );

  const selectedPractices = mapToJSValues('practices');
  const excludedPractices = mapToJSValues('excludedPractices');
  const selectedZeroClaimLineOption = mapToJSValues('onlyZeroPayRule', {});
  const isSelectedZeoClaimLineOptionWithoutAnyClaimLine =
    selectedZeroClaimLineOption?.value !== 'ANY_CLAIM_LINE';

  const isZeroPayAnyClaimLineDisabled =
    selectedProcedureCodes.length > 1 ||
    selectedProcedureModifierCodes.length > 1 ||
    selectedRemarks.length > 1 ||
    selectedReasonCodes.length > 1;
  return {
    allAgents,
    selectedAgents,
    selectedPayers,
    excludedPayers,
    selectedRemarks,
    excludedRemarks,
    selectedPractices,
    excludedPractices,
    selectedProcedureCodes,
    excludedProcedureCodes,
    selectedReasonCodes,
    excludedReasonCodes,
    selectedProcedureModifierCodes,
    excludedProcedureModifierCodes,
    isOnlyZeroPaySelected,
    isZeroPayAnyClaimLineDisabled,
    isSelectedZeoClaimLineOptionWithoutAnyClaimLine,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({ change }, dispatch),
  };
};

ImportForm = connect(mapStateToProps, mapDispatchToProps)(ImportForm);

export default ImportForm;
